<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

        <base-material-tabs
          v-model="active_tab"
          color="warning"
          icons-and-text
        >
      <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

        <v-tabs-items
          v-model="active_tab"
          class="pt-5 transparent"
        >

          <v-tab-item>
            <base-material-card
              icon="mdi-email-receive-outline"
              title="Disposisi Surat"
              class="px-5 py-3"
              >
                <v-data-table
                    :headers="headers"
                    :items="listDataDispo"
                    class="elevation-1"
                    @selected="getListDisposisi()"
                  >

                  <template v-slot:item.subject="{ item }">
                    <span>{{ item.Notes }}</span><br>
                    <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix : ''}} {{item.senderpos}})</span></span>
                  </template>

                  <template v-slot:item.log_to="{ item }">
                    <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                    <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                  </template>

                  <template v-slot:item.aksi="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(1, item.id_letter, item.log_to, item.id)" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Surat</span>
                    </v-tooltip>
                    &nbsp;

                   <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
      
                    
                  </template>
                </v-data-table>

            </base-material-card>
          </v-tab-item>
          <v-tab-item>
            <base-material-card
              icon="mdi-clipboard-text"
              title="Permintaan Tandatangan"
              class="px-5 py-3"
              >
                <v-data-table
                    :headers="headers"
                    :items="listDataTtd"
                    class="elevation-1"
                    @selected="getListTTD()"
                  >
                  <template v-slot:item.subject="{ item }">
                    <span>{{ item.Notes }}</span><br>
                    <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix :''}} {{item.senderpos}})</span></span>
                  </template>
                  <template v-slot:item.log_to="{ item }">
                    <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                    <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                  </template>
                  <template v-slot:item.aksi="{ item }">
                     <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(2, item.id_letter, item.log_to, item.id)" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Surat</span>
                    </v-tooltip>
                    &nbsp;

                   <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
      
                
                  </template>
                </v-data-table>

            </base-material-card>
          </v-tab-item>
          
          <v-tab-item>
            <base-material-card
              icon="mdi-clipboard-text"
              title="Share Surat"
              class="px-5 py-3"
              >
                  <v-data-table
                      :headers="headers"
                      :items="listDataShare"
                      class="elevation-1"
                      @selected="getListSHARE()"
                    >
                    <template v-slot:item.subject="{ item }">
                        <span>{{ item.Notes }}</span><br>
                        <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix :''}} {{item.senderpos}})</span></span>
                      </template>
                    <template v-slot:item.log_to="{ item }">
                      <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                    <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                  </template>
                    <template v-slot:item.aksi="{ item }">

                       <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(3, item.id_letter, item.log_to, item.id)" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Surat</span>
                    </v-tooltip>
                    &nbsp;

                      <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
        
                    </template>
                  </v-data-table>

              </base-material-card>
              </v-tab-item>
              <v-tab-item>
                <base-material-card
                  icon="mdi-clipboard-text"
                  title="Komentar"
                  class="px-5 py-3"
                  >
                    <v-data-table
                        :headers="headers"
                        :items="listDataComment"
                        class="elevation-1"
                        @selected="getListComment()"
                      >
                      <template v-slot:item.subject="{ item }">
                        <span>{{ item.Notes }}</span><br>
                        <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix :''}} {{item.senderpos}})</span></span>
                      </template>
                      <template v-slot:item.log_to="{ item }">
                        <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                        <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                      </template>
                      <template v-slot:item.aksi="{ item }">

                         <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(4, item.id_letter, item.log_to, item.id)" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Surat</span>
                    </v-tooltip>
                    &nbsp;
                      <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
          
                      </template>
                    </v-data-table>

                </base-material-card>
              </v-tab-item>
              <v-tab-item>
                <base-material-card
                  icon="mdi-clipboard-text"
                  title="Arsip Surat Masuk"
                  class="px-5 py-3"
                  >
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="listQuery.search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col>
                      <v-btn color="blue" @click="getListAllOpened()">Search</v-btn>
                      </v-col>
                      <v-col class="text-right">
                        &nbsp;
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-data-table
                        :headers="headers"
                        :items="listDataArsip"
                        :items-per-page="15"
                        class="elevation-1"
                        :server-items-length="totalData"
                        :loading="listLoading"
                        :options.sync="tableOpt"
                      >
                      
                      <template v-slot:item.subject="{ item }">
                        <span v-if="item.eostatus_to.id == 25"><v-icon>mdi-clipboard-text</v-icon></span><!-- DISPOSISI-->
                        <span v-else-if="item.eostatus_to.id == 26"><v-icon>mdi-mail</v-icon></span><!-- TTD-->
                        <span v-else-if="item.eostatus_to.id == 27"><v-icon>mdi-share</v-icon></span><!-- SHARE-->
                        <span v-else-if="item.eostatus_to.id == 28"><v-icon>mdi-forum</v-icon></span><!-- KOMENTAR-->
                        <span v-else><v-icon>mdi-information</v-icon></span><!-- LAINNYA-->
                        &nbsp;<span>{{ item.Notes }}</span><br>
                        <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix :''}} {{item.senderpos}})</span></span>
                      </template>
                      <template v-slot:item.log_to="{ item }">
                        <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                        <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                      </template>
                      <template v-slot:item.aksi="{ item }">

                         <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(5, item.id_letter, item.log_to, item.id)" >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Buka Surat</span>
                    </v-tooltip>
                    &nbsp;

                       <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
                        
                      </template>
                    </v-data-table>
                    
                </base-material-card>
              </v-tab-item>
              <v-tab-item>
                <base-material-card
                  icon="mdi-clipboard-text"
                  title="Important"
                  class="px-5 py-3"
                  >
                    <v-data-table
                        :headers="headers"
                        :items="listDataImportant"
                        class="elevation-1"
                        @selected="getListImportant()"
                      >
                      <template v-slot:item.subject="{ item }">
                        <span v-if="item.eostatus_to.id == 25"><v-icon>mdi-clipboard-text</v-icon></span><!-- DISPOSISI-->
                        <span v-else-if="item.eostatus_to.id == 26"><v-icon>mdi-mail</v-icon></span><!-- TTD-->
                        <span v-else-if="item.eostatus_to.id == 27"><v-icon>mdi-share</v-icon></span><!-- SHARE-->
                        <span v-else-if="item.eostatus_to.id == 28"><v-icon>mdi-forum</v-icon></span><!-- KOMENTAR-->
                        <span v-else><v-icon>mdi-information</v-icon></span><!-- LAINNYA-->
                        &nbsp;<span>{{ item.Notes }}</span><br>
                        <span style="font-size:11px;">Pengirim: <b>{{ item.sender }}</b> <span class="font-italic">({{ item.senderprefix ? item.senderprefix :''}} {{item.senderpos}})</span></span>
                      </template>
                      <template v-slot:item.log_to="{ item }">
                        <span style="font-size:12px;">{{ item.created_at | formatDate }}</span><br>
                        <a style="font-size:12px;" @click="showDetailLog(item.log_to)">Buka Detil Log</a>
                      </template>
                      <template v-slot:item.aksi="{ item }">

                         <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn color="primary " v-on="on" fab x-small @click="viewProcess(6, item.id_letter, item.log_to, item.id)" >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Buka Surat</span>
                        </v-tooltip>
                        &nbsp;

                      <v-tooltip top v-if="item.to_isImportant == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="item.to_isImportant == 1" color="warning" v-on="on" fab x-small @click="setIsNOTImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set NOT Important</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="grey" v-on="on" fab x-small @click="setIsImportant(item.id)" >
                            <v-icon>mdi-alert-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Set is Important</span>
                      </v-tooltip>
                        
                      </template>
                    </v-data-table>

                </base-material-card>
              </v-tab-item>

            </v-tabs-items>
        
        </base-material-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-left"
        cols="6"
        md="6">
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
        <div class="display-1">LEGEND</div>
        <hr>
           <table>
          <tr>
            <td><span><v-icon>mdi-clipboard-text</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>DISPOSISI SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-mail</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>TTD/ PERSETUJUAN SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-share</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>SHARE SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-forum</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>KOMENTAR SURAT</span></td>
          </tr>
          <tr>
            <td><span><v-icon>mdi-information</v-icon></span><!-- DISPOSISI--></td>
            <td>:</td>
            <td><span>LAINNYA</span></td>
          </tr>
        </table>
        </v-alert>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="warning darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeletedata()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="openDetailLog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Detil Log</v-card-title>
          <v-card-text><span style="font-size:12px;" v-html="strDetailLog"></span></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="openDetailLog = false">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


  </v-container>
</template>

<script>
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { getSMImportant, getSMDisposisi, getSMDisposisiHasDispo, getSMTTD, getSMShare, getSMShareHasDispo, getSMComment, getSMAllOpened, countSMAllOpened, deleteLetterInOut_TO, updateLetterInOut } from '@/api/letterinout'
  import { getletterGUID } from '@/api/datasurat'
  import { _GLOBAL } from '@/api/server'

  export default {
    name: 'SuratMasuk',

    data: () => ({
      active_tab: 1,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'DISPOSISI',
          icon: 'mdi-clipboard-text',
        },
        {
          text: 'PERMINTAAN TTD',
          icon: 'mdi-mail',
        },
        {
          text: 'SHARE',
          icon: 'mdi-share',
        },
        {
          text: 'KOMENTAR',
          icon: 'mdi-forum',
        },
        {
          text: 'ARSIP SM',
          icon: 'mdi-information',
        },
        {
          text: 'IMPORTANT',
          icon: 'mdi-alert-circle-outline',
        }
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          width:'50',
        },
        { text: 'Perihal', value: 'subject', align: 'left', class:'subtitle-1' },
        { text: 'Tanggal', value: 'log_to',  width:'15%',  align: 'center'},
        { text: 'Aksi', value: 'aksi', width:'150', align: 'center'}
      ],
      isLoading: false,
      listDataDispo: [],
      listDataTtd: [],
      listDataShare: [],
      listDataComment: [],
      listDataArsip: [],
      listDataImportant: [],
      delConfirm: false,
      openDetailLog: false,
      strDetailLog: '',
      idDel: 0,
      currRole: null,
      myuser: null,
      totalData: 0,
      listLoading: false,
      tableOpt: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: ''
      }

    }),
    watch: {
      tableOpt: {
        handler () {
          this.getListAllOpened()
        },
        deep: true,
      },
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      const idtab = this.$route.params && this.$route.params.idtab
      vm.active_tab = parseInt(idtab)

      vm.currRole = secureStorage.getItem('currRole')
      vm.myuser = secureStorage.getItem('user')
      // console.log(vm.currRole)

      vm.refreshData()
    },
    methods: {
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
          console.log(errmsg)
          vm.$alert(errmsg)
        }
      },
      showDetailLog(slog){
        this.strDetailLog = ''
        this.openDetailLog = true
        this.strDetailLog = slog
      },
      viewProcess(idType, idLetter, logto, idSM){
        const vm = this
        vm.isLoading = true
        
        // console.log('viewProcess', idLetter)
        getletterGUID(idLetter).then(response => {
          var data = response.data
          // console.log(data)
          if(data){
            var uri = _GLOBAL.PORTALURL + '/#/surat/v/' + data[0].letterGUID
            var open = window.open(uri,'_blank')
            if (!open || open == null || typeof(open)=='undefined'){
              var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri 
              console.log(errmsg)
              vm.$alert(errmsg)
            }else{
              /* No Need to remove from the list
              if(idType == 1) { //Dispo
                vm.listDataDispo = vm.listDataDispo.filter(i=>i.id != idSM)
              }else if(idType == 2) { //ttd
                vm.listDataTtd = vm.listDataTtd.filter(i=>i.id != idSM)
              }else if(idType == 3) { //share
                vm.listDataShare = vm.listDataShare.filter(i=>i.id != idSM)
              }else if(idType == 4) { //comment
                vm.listDataComment = vm.listDataComment.filter(i=>i.id != idSM)
              }else if(idType == 5) { //arsip
                // vm.listDataArsip = vm.listDataArsip.filter(i=>i.id != idSM) // Don't nedd to remove from list  
              }else if(idType == 6) { //important
                // vm.listDataImportant = vm.listDataImportant.filter(i=>i.id != idSM) // Don't nedd to remove from list
              }
              */
            }
            vm.isLoading = false

          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      refreshData(){
        this.getListDisposisi()
        this.getListTTD()
        this.getListSHARE()
        this.getListComment()
        this.getListAllOpened()
        this.getListImportant()
      },
      getListDisposisi () {
        const vm = this
        if(vm.currRole == null) return false
        vm.isLoading = true
        vm.listDataDispo = []
        
        var idpos = vm.currRole.hrposition.id
        var iduserpos = vm.currRole.id
        var isHead = vm.currRole.hrposition.is_headunit

        getSMDisposisiHasDispo(iduserpos, idpos, isHead).then(response => {
            var data = response.data
            // console.log('getSMDisposisi', data)
            var users = secureStorage.getItem('userall')
            var posall = secureStorage.getItem('posall')
            if (data.length > 0) {
              data.forEach(element => {
                // console.log('getSMDisposisi', element)

                if(element.Notes){
                  var note = element.Notes
                  note = note.replace('undefined - ','')
                  element.Notes = note
                }

                var sender = users.find(x => x.id === element.hruserpositionsfrom_user)
                var senderpos = posall.find(x => x.id === element.hruserpositionsfrom_hrpositions)
                element['sender'] = sender.fullname
                element['senderprefix'] = element.hruserpositionsfrom_prefix
                element['senderpos'] = senderpos.pos_name
                if(element['created_at'] && element['updated_at']){
                  element['created_at'] = moment(element['created_at']).add(7,'hours');
                  element['updated_at'] = moment(element['updated_at']).add(7,'hours');
                }
              })
            }
            vm.listDataDispo = data
            // console.log('listDataDispo', vm.listDataDispo)
            vm.isLoading = false
          }).catch(err => {
            // console.log(err)
            vm.isLoading = false
          })
      },
      getListTTD () {
        const vm = this
        if(vm.currRole == null) return false
        vm.isLoading = true
        vm.listDataTtd = []
        var idparam = 0

        if(vm.currRole.hrposition.is_headunit){
          idparam = vm.currRole.hrposition.id
        }else{
          idparam = vm.currRole.id
        }
        // console.log('idparam', idparam)
        // console.log('vm.currRole.hrposition.is_headunit', vm.currRole.hrposition.is_headunit)
        getSMTTD(idparam,vm.currRole.hrposition.is_headunit).then(response => {
          var data = response.data
          // console.log('getSMTTD', data)
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')

          if (data.length > 0) {
            data.forEach(element => {
              // console.log('getSMTTD', element)
              // console.log('element.hruserpositionsfrom_user', element.hruserpositionsfrom_user)
              var sender = users.find(x => x.id === element.hruserpositionsfrom_user)
              var senderpos = posall.find(x => x.id === element.hruserpositionsfrom_hrpositions)
              // console.log('element',element)
              element['sender'] = sender.fullname
              element['senderpos'] = senderpos.pos_name
              element['senderprefix'] = element.hruserpositionsfrom_prefix
              if(element['created_at'] && element['updated_at']){
                element['created_at'] = moment(element['created_at']).add(7,'hours');
                element['updated_at'] = moment(element['updated_at']).add(7,'hours');
              }
              
            })
          }
          vm.listDataTtd = data
          // console.log('listDataTtd', vm.listDataTtd)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      getListSHARE () {
        const vm = this
        if(vm.currRole == null) return false
        vm.isLoading = true
        vm.listDataShare = []

        var idpos = vm.currRole.hrposition.id
        var iduserpos = vm.currRole.id
        var isHead = vm.currRole.hrposition.is_headunit

        getSMShareHasDispo(iduserpos, idpos, isHead).then(response => {
          var data = response.data
          // console.log('getSMShareHasDispo', data)

          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          if (data.length > 0) {
            data.forEach(element => {
              // console.log('getListSHARE', element)
              if(element.Notes){
                var note = element.Notes
                note = note.replace('undefined - ','')
                element.Notes = note
              }
              var sender = users.find(x => x.id === element.hruserpositionsfrom_user)
              var senderpos = posall.find(x => x.id === element.hruserpositionsfrom_hrpositions)
              element['sender'] = sender.fullname
              element['senderpos'] = senderpos.pos_name
              element['senderprefix'] = element.hruserpositionsfrom_prefix
              if(element['created_at'] && element['updated_at']){
                element['created_at'] = moment(element['created_at']).add(7,'hours');
                element['updated_at'] = moment(element['updated_at']).add(7,'hours');
              }
            })
          }
          vm.listDataShare = data
          // console.log('listDataShare', vm.listDataShare)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
        
      },
      getListComment () {
        const vm = this
        if(vm.currRole == null) return false
        vm.isLoading = true
        vm.listDataComment = []
        getSMComment(vm.currRole.id).then(response => {
          var data = response.data
          // console.log('getSMComment', data)
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          if (data.length > 0) {
            data.forEach(element => {
              // console.log('getListComment', element)
              if(element.Notes){
                var note = element.Notes
                note = note.replace('undefined - ','')
                element.Notes = note
              }
              var sender = users.find(x => x.id === element.hruserpositions_from.user)
              var senderpos = posall.find(x => x.id === element.hruserpositions_from.hrposition)
              element['sender'] = sender.fullname
              element['senderpos'] = senderpos.pos_name
              element['senderprefix'] = element.hruserpositionsfrom_prefix
              if(element['created_at'] && element['updated_at']){
                element['created_at'] = moment(element['created_at']).add(7,'hours');
                element['updated_at'] = moment(element['updated_at']).add(7,'hours');
              }
            })
          }
          vm.listDataComment = data
          // console.log('listDataComment', vm.listDataComment)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      getListImportant () {
        const vm = this
        if(vm.currRole == null) return false
        vm.isLoading = true
        vm.listDataImportant = []
        getSMImportant(vm.currRole.id).then(response => {
          var data = response.data
          var users = secureStorage.getItem('userall')
          var posall = secureStorage.getItem('posall')
          if (data.length > 0) {
            data.forEach(element => {
              // console.log('getSMImportant', element)
              if(element.Notes){
                var note = element.Notes
                note = note.replace('undefined - ','')
                element.Notes = note
              }
              var sender = users.find(x => x.id === element.hruserpositions_from.user)
              var senderpos = posall.find(x => x.id === element.hruserpositions_from.hrposition)
              // console.log('sender', sender)
              // console.log('senderpos', sender)
              element['sender'] = sender.fullname
              element['senderpos'] = senderpos.pos_name
              element['senderprefix'] = element.hruserpositionsfrom_prefix
              if(element['created_at'] && element['updated_at']){
                element['created_at'] = moment(element['created_at']).add(7,'hours');
                element['updated_at'] = moment(element['updated_at']).add(7,'hours');
              }
            })
          }
          vm.listDataImportant = data
          // console.log('listDataImportant', vm.listDataImportant)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      getListAllOpened () {
        const vm = this
        if(vm.currRole == null) return false
        vm.listDataArsip = []

        const { sortBy, sortDesc, page, itemsPerPage } = vm.tableOpt
        vm.listQuery.page = page
        vm.listQuery.limit = itemsPerPage
        if(!itemsPerPage) {
          vm.listQuery.page = 1
          vm.listQuery.limit = 15
        }else {
          // console.log('itemsPerPage: ',itemsPerPage)
          vm.listQuery.limit = itemsPerPage == -1 ? vm.totalData : itemsPerPage
        }
        // console.log('listQuery: ',vm.listQuery)
        // get total data
        vm.listLoading = true
        countSMAllOpened(vm.listQuery, vm.currRole.id).then(response => {
          var data = response.data

          vm.totalData = parseInt(data)
          // console.log(vm.totalData)
          vm.listLoading = false
        })
        // get list of data
        vm.listLoading = true
        getSMAllOpened(vm.listQuery, vm.currRole.id).then(response => {
          // console.log(response.data)
          var array = response.data
          var users = secureStorage.getItem('userall')
          // console.log('users', users)
          var posall = secureStorage.getItem('posall')
          array.forEach(element => {
            // console.log('getSMAllOpened', element)
            if(element.Notes){
                var note = element.Notes
                note = note.replace('undefined - ','')
                element.Notes = note
              }
            var sender = users.find(x => x.id === element.hruserpositions_from.user)
            var senderpos = posall.find(x => x.id === element.hruserpositions_from.hrposition)
            element['sender'] = sender.fullname
            element['senderpos'] = senderpos.pos_name
            element['senderprefix'] = element.hruserpositionsfrom_prefix
            if(element['created_at'] && element['updated_at']){
                element['created_at'] = moment(element['created_at']).add(7,'hours');
                element['updated_at'] = moment(element['updated_at']).add(7,'hours');
              }
          })
          if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
            array = array.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          vm.listDataArsip = array
          vm.listLoading = false
        })
      },
      delData (id) {
        const vm = this
        vm.idDel = id
        vm.delConfirm = true
      },
      doDeletedata () {
        const vm = this
        vm.delConfirm = false
        if (vm.idDel == 0) {
          return false
        }
        vm.isLoading = true
        deleteLetterInOut_TO(vm.idDel).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.refreshData()
          vm.toast = {
            show: true, color:'green', text: 'Surat Masuk berhasil terhapus.', timeout: 2000
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
           vm.toast = {
            show: true, color:'red', text: 'Surat Masuk GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
          }
        })
      },
      setIsImportant(id){
        const vm = this
        var form = {
          to_isImportant: 1
        }
        vm.isLoading = true
        updateLetterInOut(form, id).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.refreshData()
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      setIsNOTImportant(id){
        const vm = this
        var form = {
          to_isImportant: 0
        }
        vm.isLoading = true
        updateLetterInOut(form, id).then(response => {
          var data = response.data
          // console.log(data)
          vm.isLoading = false
          vm.refreshData()
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      }
    }
  }
</script>
